if (typeof globalThis === 'undefined') {
  ;(window as any).globalThis = window
}

// 添加 Promise.finally polyfill
if (!Promise.prototype.finally) {
  Promise.prototype.finally = function (callback) {
    const P = this.constructor
    return this.then(
      (value) => P.resolve(callback()).then(() => value),
      (reason) =>
        P.resolve(callback()).then(() => {
          throw reason
        })
    )
  }
}
