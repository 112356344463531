import { Badge } from 'antd'
import { Card } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getText } from '../../../../utils/util'
import './index.css'

export default function CenterPage({ listData, readAll, delAll, readOne, delOne }) {
  const navigate = useNavigate()
  const { t: translate } = useTranslation()
  const la = localStorage.getItem('i18n')

  // console.log('faith=============listData', listData)
  return (
    <>
      {/* list */}
      {listData.map((data) => (
        <div
          style={{
            padding: 10
          }}>
          {data[la === 'zh' ? 'content' : 'contentEn'] && (
            <Card
              title={
                <Badge count={data.status == 1 ? 2 : 0} offset={[5, 0]} dot size={'default'}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '85vw' }}>
                    <span>{data[la === 'zh' ? 'title' : 'titleEn']} </span>
                    <span className='text-[9px] text-gray-300' style={{ fontWeight: 300 }}>
                      {data.addtime}
                    </span>
                  </div>
                </Badge>
              }>
              {data[la === 'zh' ? 'content' : 'contentEn']}
            </Card>
          )}
        </div>
      ))}
      {/* null */}
      {listData.length === 0 && (
        <div className='noice-1'>
          <div className='noice-2'>
            <img alt='' src='/noice/empty-image-default.png' className='noice-3' />
          </div>
          <p className='noice-4'>{translate(getText('無留言記錄'))}</p>
        </div>
      )}
    </>
  )
}
